.image-container {
    position: relative;
    text-align: center;
    color: white;
    font-family: 'Arial', sans-serif; /* Un font più leggibile */
}

.background-image {
    width: 100%;
    height: auto;
    border-radius: 8px; /* Aggiunge bordi arrotondati all'immagine */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Aggiunge ombra per un effetto 3D leggero */
}

.text-overlay {
    position: absolute;
    bottom: 10px; /* Posiziona il testo vicino al bordo inferiore dell'immagine */
    left: 50%;
    transform: translateX(-50%); /* Centra il testo orizzontalmente */
    width: 100%; /* Estende il div dell'overlay per tutta la larghezza dell'immagine */
    text-align: center; /* Allinea il testo al centro dell'overlay */
    color: black;
    font-size: 18px; /* Dimensione del font adatta per la lettura */
    background-color: rgba(255, 255, 255, 0.6); /* Sfondo semi-trasparente per leggere meglio il testo */
    padding: 10px 20px; /* Padding per dare spazio intorno al testo */
    border-radius: 8px; /* Bordi arrotondati per l'overlay del testo */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Piccola ombra per un effetto di profondità */
}


.download-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.download-button:hover {
    background-color: #0056b3;
}

.attivatore-highlight {
    color: red;
    font-weight: bold;
  }
  
  .logo-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }