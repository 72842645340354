.container-top {
  background-color: #d4822f;
  /* Sfondo chiaro per risaltare il container */
  border: 1px solid #0e0d0d;
  /* Bordo sottile per definire i confini */
  border-radius: 5px;
  /* Angoli arrotondati per un aspetto più morbido */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Ombra leggera per un effetto di profondità */
  padding: 20px;
  /* Spazio interno per non attaccare il contenuto ai bordi */
  margin-bottom: 20px;
  /* Spazio esterno per separarlo dagli elementi sottostanti */
}

/* styles.css */

/* Stili per il contenitore della griglia */
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
}

/* Stili per ogni elemento della griglia */
.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* Stili base per le immagini all'interno degli elementi della griglia */
.responsive-img {
  width: 100%;
  /* L'immagine occupa sempre il 100% della larghezza del contenitore */
  object-fit: contain;
  /* Mantiene le proporzioni senza tagliare l'immagine */
}

/* Media query per schermi medi (es. tablet) */
@media (min-width: 768px) {
  .responsive-img {
    max-height: 300px;
    /* Aumenta l'altezza massima per dispositivi più grandi */
  }
}

/* Media query per schermi grandi (es. desktop) */
@media (min-width: 992px) {
  .responsive-img {
    max-height: 400px;
    /* Aumenta ulteriormente l'altezza massima per schermi ancora più grandi */
  }
}