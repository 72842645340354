.container-top {
  background-color: #d4822f;
  /* Sfondo chiaro per risaltare il container */
  border: 1px solid #0e0d0d;
  /* Bordo sottile per definire i confini */
  border-radius: 5px;
  /* Angoli arrotondati per un aspetto più morbido */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Ombra leggera per un effetto di profondità */
  padding: 20px;
  /* Spazio interno per non attaccare il contenuto ai bordi */
  margin-bottom: 20px;
  /* Spazio esterno per separarlo dagli elementi sottostanti */
}

.referenza-fmm {
  margin-left: 6px;
  /* Aggiungi il margine che preferisci */
  color: red;
  font-weight: bold;
}