/* styles.css */

/* Stili per il contenitore della griglia */
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    justify-content: center;
  }
  
  /* Stili per ogni elemento della griglia */
  .grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  /* Stili base per le immagini all'interno degli elementi della griglia */
  .responsive-img {
    width: 100%; /* L'immagine occupa sempre il 100% della larghezza del contenitore */
    object-fit: contain; /* Mantiene le proporzioni senza tagliare l'immagine */
  }
  
  /* Media query per schermi medi (es. tablet) */
  @media (min-width: 768px) {
    .responsive-img {
      max-height: 300px; /* Aumenta l'altezza massima per dispositivi più grandi */
    }
  }
  
  /* Media query per schermi grandi (es. desktop) */
  @media (min-width: 992px) {
    .responsive-img {
      max-height: 400px; /* Aumenta ulteriormente l'altezza massima per schermi ancora più grandi */
    }
  }
  